<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>Список пользователей
            <v-select
                value=""
                :items="roleOptions"
                item-text="text"
                item-value="roleOptionCode"
                style="width: 40%"
                @change="onSelectChange($event)"></v-select>
            <div class="header-buttons">

              <div class="menu-button search-menu-button" @click="$router.push('/config/userEdit')">
                <v-icon class="icon-item icon-account-plus"></v-icon>
              </div>
            </div>
          </h1>

          <div class="commonFilter">
            <v-menu style="max-width: 300px"
                    :nudge-bottom="38"
                    transition="scale-transition"
                    :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  Фильтр
                </v-btn>
              </template>
              <v-card class="filterByField">
                <v-text-field
                        label="Имя пользователя"
                        v-model="search.username"
                        @input="filterByFields()"
                ></v-text-field>
                <v-text-field
                        label="Полное имя"
                        v-model="search.name"
                        @input="filterByFields()"
                ></v-text-field>
                <v-text-field
                        label="Роль"
                        v-model="search.role"
                        @input="filterByFields()"
                ></v-text-field>
                <v-text-field
                        label="Email"
                        v-model="search.email"
                        @input="filterByFields()"
                ></v-text-field>
                <v-text-field
                        label="Регион"
                        v-model="search.region"
                        @input="filterByFields()"
                ></v-text-field>
              </v-card>
            </v-menu>
            <v-btn outlined
                   v-if="search.username || search.name || search.role || search.email"
                   @click="filterReset()">
              Сбросить все
            </v-btn>
          </div>

          <table class="anketsRegister-table">
            <thead>
              <tr>
                <th>
                  <div>
                    <span @click="sortByField('username')">Имя пользователя</span>
                    <v-menu style="max-width: 300px"
                            :nudge-bottom="38"
                            transition="scale-transition"
                            :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <div class="icon-search"></div>
                        </div>
                      </template>
                      <v-card class="filterByField">
                        <v-text-field
                                label="Имя пользователя"
                                v-model="search.username"
                                @input="filterByField({
                                  field: 'username',
                                  text: $event,
                                })"
                        ></v-text-field>
                      </v-card>
                    </v-menu>
                  </div>
                </th>
                <th>
                  <div>
                    <span @click="sortByField('name')">Полное имя</span>
                    <v-menu style="max-width: 300px"
                            :nudge-bottom="38"
                            transition="scale-transition"
                            :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <div class="icon-search"></div>
                        </div>
                      </template>
                      <v-card class="filterByField">
                        <v-text-field
                                label="Полное имя"
                                v-model="search.name"
                                @input="filterByField({
                                  field: 'name',
                                  text: $event,
                                })"
                        ></v-text-field>
                      </v-card>
                    </v-menu>
                  </div>
                </th>
                <th>
                  <div>
                    <span @click="sortByField('primaryRole')">Роль</span>
                    <v-menu style="max-width: 300px"
                            :nudge-bottom="38"
                            transition="scale-transition"
                            :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <div class="icon-search"></div>
                        </div>
                      </template>
                      <v-card class="filterByField">
                        <v-text-field
                                label="Роль"
                                v-model="search.role"
                                @input="filterByField({
                                  field: 'primaryRole',
                                  text: $event,
                                })"
                        ></v-text-field>
                      </v-card>
                    </v-menu>
                  </div>
                </th>
                <th>
                  <div>
                    <span @click="sortByField('email')">Email</span>
                    <v-menu style="max-width: 300px"
                            :nudge-bottom="38"
                            transition="scale-transition"
                            :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <div class="icon-search"></div>
                        </div>
                      </template>
                      <v-card class="filterByField">
                        <v-text-field
                                label="Email"
                                v-model="search.email"
                                @input="filterByField({
                                  field: 'email',
                                  text: $event,
                                })"
                        ></v-text-field>
                      </v-card>
                    </v-menu>
                  </div>
                </th>
                <th>
                  <div>
                    <span @click="sortByField('region')">Регион</span>
                    <v-menu style="max-width: 300px"
                            :nudge-bottom="38"
                            transition="scale-transition"
                            :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <div class="icon-search"></div>
                        </div>
                      </template>
                      <v-card class="filterByField">
                        <v-text-field
                                label="Регион"
                                v-model="search.region"
                                @input="filterByField({
                                  field: 'region',
                                  text: $event,
                                })"
                        ></v-text-field>
                      </v-card>
                    </v-menu>
                  </div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="u in usersList" :key="'ssdds'+u.primaryRole+u.id">
              <td>
                <div>
                <router-link :to="'/config/userEdit/' +u.id" :style="u.enabled ? '' : 'text-decoration: line-through'">
                  {{ u.username }}
                </router-link>
                </div>
              </td>
              <td style="white-space: normal;">
                <div>{{ u.name }}</div>
              </td>
              <td>
                <div>{{
                  u.primaryRole === 'ROLE_CZN_MANAGER'  ? 'Управляющий ЦЗН' : (u.primaryRole === 'ROLE_CZN_TERRITORIAL' ? 'Территориальный ЦЗН' : (u.primaryRole === 'ROLE_VNII' ? 'Сотрудник ВНИИ Труда' : (u.primaryRole === 'ROLE_REGIONAL_AGENCY' ? 'Региональный орган занятости' : (u.primaryRole === 'ROLE_REGIONAL_ADMINISTRATOR' ? 'Региональный администратор' : (u.primaryRole === 'ROLE_ADMIN' ? 'Администратор системы' : 'неизвестная роль')))))
                  }}</div>
              </td>
              <td>
                <div>{{ u.email ? u.email : '&mdash;' }}</div>
              </td>
              <td>
                <div>{{ u?.region?.name ? u.region.name : '&mdash;' }}</div>
              </td>
              <td>
                <div class="edit" @click="$router.push('/config/userEdit/' +u.id)">
                  <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                </div>
              </td>
              <td>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>

export default {
  props: {},
  components: {},
  data() {
    return {
      search: {
        username: null,
        name: null,
        role: null,
        email: null,
        region: null,
      },
      cznUsers: [],
      users: [],
      usersList: [],
      apiLoaded: false,
      errorText: null,
      sortAsc: false,
      curSortField: null,
      roleOptions: [
        {roleOptionCode: "", text: "Все роли"},
        {roleOptionCode: "ROLE_CZN_MANAGER", text: "Управляющие ЦЗН"},
        {roleOptionCode: "ROLE_CZN_TERRITORIAL", text: "Территориальные ЦЗН"},
        {roleOptionCode: "ROLE_ADMIN", text: "Администраторы"},
        {roleOptionCode: "ROLE_VNII", text: "Сотрудники ВНИИ Труда"},
        {roleOptionCode: "ROLE_REGIONAL_AGENCY", text: "Региональные органы занятости"},
        {roleOptionCode: "ROLE_REGIONAL_ADMINISTRATOR", text: "Региональный администратор"},
        {roleOptionCode: "ROLE_MINTRUD", text: "Минтруд и Роструд"}
      ]
    };
  },
  methods: {
    async loadUsers() {
      let req = await this.$getApi('/getUserList')
      if (req.ok) {
        this.users = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    showAllUsers() {
      this.usersList = []
      this.usersList = this.usersList.concat(this.users)
    },

    showManagerCznOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_CZN_MANAGER')
          this.usersList.push(u)
    },

    showTerritorialCznOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_CZN_TERRITORIAL')
          this.usersList.push(u)
    },

    showVniiOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_VNII')
          this.usersList.push(u)
    },

    showAdminOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_ADMIN')
          this.usersList.push(u)
    },

    showRegionalAgencyOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_REGIONAL_AGENCY')
          this.usersList.push(u)
    },

    showRegionalAdministratorsOnly(){
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_REGIONAL_ADMINISTRATOR')
          this.usersList.push(u)
    },

    showMintrudOnly(){
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_MINTRUD')
          this.usersList.push(u)
    },

    onSelectChange(opt){
      if (opt === '')
        this.showAllUsers()
      else if (opt === 'ROLE_CZN_MANAGER')
        this.showManagerCznOnly()
      else if (opt === 'ROLE_CZN_TERRITORIAL')
        this.showTerritorialCznOnly()
      else if (opt === 'ROLE_ADMIN')
        this.showAdminOnly()
      else if (opt === 'ROLE_VNII')
        this.showVniiOnly()
      else if (opt === 'ROLE_REGIONAL_AGENCY')
        this.showRegionalAgencyOnly()
      else if (opt === 'ROLE_REGIONAL_ADMINISTRATOR')
        this.showRegionalAdministratorsOnly()
      else if (opt === 'ROLE_MINTRUD')
        this.showMintrudOnly()
    },

    sortByField(field){
      if(this.curSortField === field)
        this.sortAsc = !this.sortAsc
      if(this.sortAsc)
        this.usersList.sort((a, b) => (a[field] < b[field]) ? 1 : -1)
      else
        this.usersList.sort((a, b) => (a[field] > b[field]) ? 1 : -1)
      this.curSortField = field
    },

    getRolesList() {
      return this.roleOptions.filter((role) => {
          return ~role.text.toLowerCase().indexOf(this.search.role.toLowerCase())
      }).map((role) => role.roleOptionCode)
    },

    filterByField(options) {
        console.log('filterByField', options)
      if (!options?.field) {
          return
      }
      if (options?.text) {
        if (options.field === 'primaryRole') {
          const roles = this.getRolesList()
          this.usersList = this.users.filter((user) => {
            return roles.includes(user[options.field])
          })
          return
        }
        if (options.field === 'region') {
          this.usersList = this.users.filter((user) => {
              if (!user[options.field]?.name) {
                  return null
              }
              return ~user[options.field].name?.toLowerCase()?.indexOf(options.text.toLowerCase())
          })
          return
        }
        this.usersList = this.users.filter((user) => {
          if (!user[options.field]) {
            return null
          }
          return ~user[options.field]?.toLowerCase()?.indexOf(options.text.toLowerCase())
        })
        return
      }
      this.showAllUsers()
    },

    filterByFields() {
      if (!this.search.username && !this.search.name && !this.search.role && !this.search.email && !this.search.region) {
        this.showAllUsers()
        return
      }
      let roles = []
      if (this.search.role) {
        roles = this.getRolesList()
      }
      this.usersList = this.users.filter((user) => {
        return (
            (this.search.username) ? (
                (user?.username) ? ~user.username.toLowerCase().indexOf(this.search.username.toLowerCase()) : 0
            ) : 1)
          && (
            (this.search.name) ? (
              (user?.name) ? ~user.name.toLowerCase().indexOf(this.search.name.toLowerCase()) : 0
            ) : 1)
          && (
            (this.search.email) ? (
              (user?.email) ? ~user.email.toLowerCase().indexOf(this.search.email.toLowerCase()) : 0
            ) : 1)
          && (
            (this.search.role) ? (
              (roles.length) ? roles.includes(user.primaryRole) : 0
            ) : 1)
          && (
            (this.search.region) ? (
              (user?.region?.name) ? ~user.region.name.toLowerCase().indexOf(this.search.region.toLowerCase()) : 0
            ) : 1)
      })
    },

    filterReset() {
      this.search.username = ''
      this.search.name = ''
      this.search.role = ''
      this.search.email = ''
      this.showAllUsers()
    }
  },
  async beforeMount() {
    await this.loadUsers()
    this.showAllUsers()
    this.apiLoaded = true

    if(this.$user.role === 'ROLE_REGIONAL_ADMINISTRATOR'){
      this.roleOptions = [
        {roleOptionCode: "", text: "Все роли"},
        {roleOptionCode: "ROLE_CZN_MANAGER", text: "Управляющие ЦЗН"},
        {roleOptionCode: "ROLE_CZN_TERRITORIAL", text: "Территориальные ЦЗН"},
        {roleOptionCode: "ROLE_REGIONAL_AGENCY", text: "Региональный орган занятости"},
        {roleOptionCode: "ROLE_REGIONAL_ADMINISTRATOR", text: "Региональный администратор"},
      ]
    }

    // let vniiUser = {
    //   name: 'Администратор системы',
    //   userName: 'admin',
    //   email: 'shevernev@yandex.ru',
    //   stopEmails: false,
    //   role: 'ROLE_ADMIN'
    // }
    //let req = await this.$postApi('/saveVniiUser', vniiUser)
    //console.log(req)
  }
};
</script>
